import React from 'react';
import PropTypes from 'prop-types';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: #F4F4F4;
  font-size: 14px;

  @media (width >= 768px) {
    font-size: 20px;
  }

  &::placeholder {
    color: #777;
  }

  &.red-error {
    &::placeholder {
      color: #D72923;
    }

    p {
      display: none;
    }
  }

  :invalid, :valid, :-webkit-autofill, :autofill {
    border:  none;
    border-radius: 6.25rem;
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1.25rem;
  }

  :user-invalid {
    border: 2px solid #D72923;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${props => (props.buttonBackgroundColor)};
  position: absolute;
  right: 8px;
  top: 22px;
  height: 36px;
  width: 36px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

// To hide default svg icon
const StyledBox = styled(Box)`
  span {
    display: none;
  }

  p#error-email {
    margin-left: 12px;
    display: none;
  }
`;

const CustomBox = styled(Box)`
  div {
    padding: 8px 0 0;
    min-height: auto;
  }
`;

const StyledTypography = styled(Typography)`
  font-size: 16px;

  @media (width >= 768px) {
    font-size: 20px;
  }
`;

function CTAVariant2({ buttonLabel, buttonBackgroundColor }) {
  return (
    <CustomBox
      backgroundColor="white.default"
      width={{ xs: '300px', md: '400px' }}
      height={{ xs: '130px', md: '140px', lg: '136px' }}
      marginTop="24px"
      borderRadius="24px"
      paddingHorizontal="18px"
      paddingTop="16px"
    >

      <StyledTypography
        intlKey={buttonLabel}
        marginTop={{ xs: '0', md: '4x', lg: '0' }}
        textAlign="left"
        strong
      />

      <StyledBox
        display="flex"
        flexDirection="column"
        position="relative"
        width="100%"
      >
        <StyledTextField
          id="email-on-hero"
          type="email"
          name="email"
          className="email-1"
          label=""
          placeholder="Escribe tu correo electrónico"
          syncValidations={{
            required: 'Revisa que tu correo esté bien',
            email: 'Revisa que tu correo esté bien',
          }}
        />

        <StyledButton
          id="email-on-hero-submit-btn"
          variant="contained"
          type="submit"
          size="small"
          buttonBackgroundColor={buttonBackgroundColor}
          iconProps={{ name: 'chevron-right', title: 'Continuar' }}
        />

      </StyledBox>

    </CustomBox>
  );
}

CTAVariant2.propTypes = {
  buttonBackgroundColor: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default CTAVariant2;
