import React from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import Rates from '../../components/Rates/Rates';
import FAQSection from '../../components/FAQ/FAQ';

import MasterBrandHero from './patterns/Hero/MasterBrandHero';
import Download from './patterns/Download/Download';
import CardsCarousel from './patterns/CardsCarousel/CardsCarousel';
import GradientNumbers from './patterns/GradientNumbers/GradientNumbers';
import SecurityAndTransparency from './patterns/SecurityAndTransparency/SecurityAndTransparency';
import Press from './patterns/Press/Press';
import Xpeer from './patterns/Xpeer/Xpeer';
import questions from './faq.json';

function HomeLite() {
  return (
    <ParallaxProvider>
      <MasterBrandHero />
      <Download />
      <CardsCarousel />
      <GradientNumbers />
      <SecurityAndTransparency />
      <Rates />
      <Press />
      <FAQSection
        questions={questions}
        openLast={false}
        openFirst
      />
      <Xpeer />
    </ParallaxProvider>
  );
}

export default HomeLite;
