import React from 'react';
import PropTypes from 'prop-types';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';

const StyledTextField = styled(TextField)`
  width: 100%;
  min-width: 320px;
  background-color: #F4F4F4;
  border-radius: 12px;
  font-size: 12px;
  white-space: break-spaces;

  &::placeholder {
    color: #777;
  }

  @media (width >= 768px) {
    min-width: 430px;
    font-size: 16px;
  }

  &.red-error {
    &::placeholder {
      color: #D72923;
    }

    p {
      display: none;
    }
  }

  :invalid, :valid, :-webkit-autofill, :autofill {
    border:  none;
    border-radius: 6.25rem;
    height: 56px;
    padding-left: 1rem;
    padding-right: 1.25rem;
  }

  :user-invalid {
    border: 2px solid #D72923;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${props => (props.buttonBackgroundColor)};
  position: absolute;
  right: 4px;
  top: 25px;
  height: 40px;
  padding-left: 8px;
  padding-right: 8px;

  @media (width >= 768px) {
      right: 8px;
      padding-left: 12px;
      padding-right: 12px;
    }

  span {
    font-size: 12px;

    @media (width >= 768px) {
      font-size: 14px;
    }
  }
`;

// To hide default svg icon
const StyledBox = styled(Box)`
  span svg {
    display: none;
  }

  p#error-email {
    margin-left: 12px;
  }
`;

function CTAVariant3({ buttonLabel, buttonBackgroundColor }) {
  return (
    <StyledBox
      display="flex"
      flexDirection="column"
      position="relative"
      width="100%"
      padddingBottom="12px"
    >
      <StyledTextField
        id="email-on-hero"
        type="email"
        name="email"
        label=""
        placeholder="Escribe tu correo electrónico"
        syncValidations={{
          required: 'Revisa que tu correo esté bien',
          email: 'Revisa que tu correo esté bien',
        }}
      />

      <StyledButton
        id="email-on-hero-submit-btn"
        intlKey={buttonLabel}
        variant="contained"
        styleVariant="primary"
        type="submit"
        size="small"
        buttonBackgroundColor={buttonBackgroundColor}
      />

    </StyledBox>
  );
}

CTAVariant3.propTypes = {
  buttonBackgroundColor: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
};

export default CTAVariant3;
