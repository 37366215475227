import React, { useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Form from '@nubank/nuds-web/components/Form/Form';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { useSiteContext } from '../SiteContext/SiteContext';
import { emailOnHeroRegisterEvent } from '../../screens/Registration/tracking';
import { ERROR_SEVERITY, sentryException } from '../../utils/sentry';
import { getDiscoveryUrls } from '../../domains/discoveryUrls/discoveryUrls';
import HeroCTA from '../HeroCTA/HeroCTA';

const StyledTypography = styled(Typography)`
  /* stylelint-disable property-no-vendor-prefix */
  background: ${props => (props.ctaGradient === 'default' ? props.color : props.ctaGradient)};
  color: ${props => (props.color)};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

function FormBoxExperiment({
  buttonBackgroundColor,
  buttonGradientColor,
  buttonLabel,
  buttonLabelColor,
  buttonStyleVariant,
  ctaColor,
  ctaFontSize,
  ctaGradient,
  ctaHide,
  ctaLabel,
  ctaMarginBottom,
  ctaMaxWidth,
  inputBoxShadow,
  inputLabel,
  inputLabelColor,
  inputTransparentBackground,
  errorMessageColor,
}) {
  const {
    setEmail,
    openRegistrationForm,
    updateIsFormBoxAvailable,
    discoveryUrlsList,
    updateDiscoveryUrlsList,
  } = useSiteContext();

  useEffect(() => {
    updateIsFormBoxAvailable(true);
  }, [updateIsFormBoxAvailable]);

  const handleSubmit = async ({
    values,
    setSubmitting,
  }) => {
    if (values.email) {
      try {
        setEmail(values.email);
        openRegistrationForm();
        if (!discoveryUrlsList) {
          const discoveryUrls = await getDiscoveryUrls();
          updateDiscoveryUrlsList(discoveryUrls);
        }
      } catch (error) {
        sentryException({
          error,
          flow: 'application_flow',
          checkpoint: 'set email and open registration form',
          namespace: '<FormBox/>',
          level: ERROR_SEVERITY.CRITICAL,
        });
      } finally {
        setSubmitting(false);

        emailOnHeroRegisterEvent();
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Box width="fit-content">
      <Form>
        <Form.Step
          initialValues={{ email: '' }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {() => (
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: 'center', lg: 'flex-start' }}
            >
              {ctaHide ? null : (
                <StyledTypography
                  intlKey={ctaLabel}
                  variant={ctaFontSize}
                  color={ctaColor}
                  ctaGradient={ctaGradient}
                  marginBottom={ctaMarginBottom}
                  marginTop={{ xs: '0', md: '4x', lg: '0' }}
                  textAlign={{ xs: 'center', lg: 'left' }}
                  maxWidth={ctaMaxWidth}
                  strong
                />
              )}

              <Box width="100%">
                <HeroCTA
                  buttonBackgroundColor={buttonBackgroundColor}
                  buttonGradientColor={buttonGradientColor}
                  buttonLabel={buttonLabel}
                  buttonLabelColor={buttonLabelColor}
                  buttonStyleVariant={buttonStyleVariant}
                  inputBoxShadow={inputBoxShadow}
                  inputLabel={inputLabel}
                  inputLabelColor={inputLabelColor}
                  inputTransparentBackground={inputTransparentBackground}
                  errorMessageColor={errorMessageColor}
                />
              </Box>

            </Box>
          )}
        </Form.Step>
      </Form>
    </Box>
  );
}

FormBoxExperiment.defaultProps = {
  buttonBackgroundColor: 'default',
  buttonLabel: 'FORMBOX.DEFAULT.BUTTON_LABEL',
  buttonLabelColor: 'default',
  buttonGradientColor: 'default',
  buttonStyleVariant: 'primary',
  ctaColor: 'white',
  ctaFontSize: 'heading4',
  ctaGradient: 'default',
  ctaHide: false,
  ctaLabel: 'FORMBOX.DEFAULT.CTA_LABEL',
  ctaMarginBottom: '2x',
  ctaMaxWidth: 'auto',
  inputBoxShadow: '0 0 0',
  inputLabel: 'FORMBOX.DEFAULT.INPUT_LABEL',
  inputLabelColor: 'default',
  inputTransparentBackground: false,
  errorMessageColor: '#FFD8D5',
};

FormBoxExperiment.propTypes = {
  buttonBackgroundColor: PropTypes.string,
  buttonGradientColor: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLabelColor: PropTypes.string,
  buttonStyleVariant: PropTypes.string,
  ctaColor: PropTypes.string,
  ctaFontSize: PropTypes.string,
  ctaGradient: PropTypes.string,
  ctaHide: PropTypes.bool,
  ctaLabel: PropTypes.string,
  ctaMarginBottom: PropTypes.string,
  ctaMaxWidth: PropTypes.string,
  errorMessageColor: PropTypes.string,
  inputBoxShadow: PropTypes.string,
  inputLabel: PropTypes.string,
  inputLabelColor: PropTypes.string,
  inputTransparentBackground: PropTypes.bool,
};

export default FormBoxExperiment;

