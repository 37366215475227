import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '@nubank/nuds-web/components/Button/Button';
import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';

import { useSiteContext } from '../../SiteContext/SiteContext';
import { emailOnHeroRegisterEvent } from '../../../screens/Registration/tracking';

const StyledButton = styled(Button)`
  ${({ buttonGradient }) => (buttonGradient ? `background: ${buttonGradient};` : '')}
  background-color: ${props => (props.buttonBackgroundColor)};
  color: ${props => (props.buttonLabelColor)};
  display: flex;
  justify-content: center;
  margin-top: 24px;
`;

function CTAVariant1({
  buttonLabel,
  buttonStyleVariant,
  buttonLabelColor,
  buttonGradientColor,
}) {
  const { openRegistrationForm } = useSiteContext();
  const { formatMessage } = useIntl();

  const handleOnClick = () => {
    openRegistrationForm();
    emailOnHeroRegisterEvent();
  };

  return (

    <Box
      minWidth="300px"
      width="300px"
    >
      <StyledButton
        id="email-on-hero-submit-btn"
        extended
        type="submit"
        intlKey={buttonLabel}
        variant="contained"
        styleVariant={buttonStyleVariant}
        buttonLabelColor={buttonLabelColor}
        buttonGradient={buttonGradientColor}
        iconProps={{
          name: 'arrow-right',
          title: formatMessage({ id: `${buttonLabel}` }),
        }}
        onClick={() => handleOnClick()}
      />
    </Box>
  );
}

CTAVariant1.propTypes = {
  buttonGradientColor: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonLabelColor: PropTypes.string.isRequired,
  buttonStyleVariant: PropTypes.string.isRequired,
};

export default CTAVariant1;
