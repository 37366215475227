import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';

import { useSiteContext } from '../../SiteContext/SiteContext';

const StyledButton = styled(Button)`
  ${({ buttonGradient }) => (buttonGradient ? `background: ${buttonGradient};` : '')}
  background-color: ${props => (props.buttonBackgroundColor)};
  color: ${props => (props.buttonLabelColor)};
  display: flex;
  justify-content: center;
`;

const StyledTextField = styled(TextField)`
  background-color: ${props => (props.inputTransparentBackground ? '#fff3' : 'white')};
  border-bottom: none;
  border-radius: 6.25rem;
  box-shadow: ${props => (props.inputBoxShadow)};
  caret-color: ${props => (props.inputLabelColor)};
  color: ${props => (props.inputLabelColor)};
  height: 3rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;

  &::placeholder {
    color: ${props => (props.inputLabelColor)};
    font-size: 14px;
    position:relative;
    text-align: center;
    top:20%;
    transform:translateY(-50%); 
  }

  &.red-error {
    &::placeholder {
      color: #D72923;
    }

    p {
      display: none;
    }
  }

  :invalid, :valid, :-webkit-autofill, :autofill {
    background-color: ${props => (props.inputTransparentBackground ? '#fff3' : 'white')};
    border:  none;
    border-radius: 6.25rem;
    box-shadow: ${props => (props.inputBoxShadow)};
    caret-color: ${props => (props.inputLabelColor)};
    height: 3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  :user-invalid {
    border: 2px solid #D72923;
  }
`;

const StyledBox = styled(Box)`
  span, p {
    display: none;
  }

  p#error-email {
    display: block;
    color: ${props => (props.errorMessageColor)};
    text-align: center;
  }

  div {
    min-height: auto;
  }
`;

function CTAControl({
  buttonBackgroundColor,
  buttonGradientColor,
  buttonLabel,
  buttonLabelColor,
  buttonStyleVariant,
  inputBoxShadow,
  inputLabel,
  inputLabelColor,
  inputTransparentBackground,
  errorMessageColor,
}) {
  const { formatMessage } = useIntl();
  const [placeholder, setPlaceholder] = useState(inputLabel);
  const { emailInputFormBox } = useSiteContext();

  const handleOnClick = () => {
    setPlaceholder('FORMBOX.INPUT.EMPTY.VALIDATION_MESSAGE');
    const placeholders = document.querySelectorAll('#email-on-hero');

    placeholders.forEach(element => {
      element.classList.add('red-error');
    });
  };

  return (
    <>
      <StyledBox
        width="100%"
        errorMessageColor={errorMessageColor}
      >
        <StyledTextField
          id="email-on-hero"
          type="email"
          name="email"
          label=""
          placeholder={formatMessage({ id: placeholder })}
          syncValidations={{
            required: 'Revisa que tu correo esté bien',
            email: 'Revisa que tu correo esté bien',
          }}
          ref={emailInputFormBox}
          inputLabelColor={inputLabelColor}
          inputBoxShadow={inputBoxShadow}
          inputTransparentBackground={inputTransparentBackground}
        />
      </StyledBox>

      <StyledButton
        id="email-on-hero-submit-btn"
        extended
        type="submit"
        intlKey={buttonLabel}
        variant="contained"
        styleVariant={buttonStyleVariant}
        buttonBackgroundColor={buttonBackgroundColor}
        buttonLabelColor={buttonLabelColor}
        buttonGradient={buttonGradientColor}
        iconProps={{
          name: 'arrow-right',
          title: formatMessage({ id: `${buttonLabel}` }),
        }}
        onClick={() => handleOnClick()}
      />
    </>
  );
}

CTAControl.propTypes = {
  buttonBackgroundColor: PropTypes.string.isRequired,
  buttonGradientColor: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  buttonLabelColor: PropTypes.string.isRequired,
  buttonStyleVariant: PropTypes.string.isRequired,
  errorMessageColor: PropTypes.string.isRequired,
  inputBoxShadow: PropTypes.string.isRequired,
  inputLabel: PropTypes.string.isRequired,
  inputLabelColor: PropTypes.string.isRequired,
  inputTransparentBackground: PropTypes.bool.isRequired,
};

export default CTAControl;
