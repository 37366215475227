/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import Image from '@nubank/nuds-web/components/Image/Image';

import ImagePreload from '@nubank/www-latam-commons/components/ImagePreload/ImagePreload';

import TypographyColorHighlight from '../../../../components/TypographyColorHighlight/TypographyColorHighlight';
import FormBoxExperiment from '../../../../components/FormBox/FormBoxExperiment';

const HERO_IMAGE = {
  xs: 'masterbrand-hero/mb-hero-xs-experiment.jpg',
  lg: 'masterbrand-hero/mb-hero-lg-experiment.jpg',
};

const StyledTypography = styled(Typography)`
  ${breakpointsMedia({
    xs: css`
      font-size: 32px;
    `,
    md: css`
      font-size: 45px;
    `,
    lg: css`
      font-size: 4.5vw;
    `,
  })}
`;

const StyledBox = styled(Box)`
  picture {
    width: 100%;
  }
`;

const HeroSection = () => {
  const [screenHeight, setScreenHeight] = useState(0);

  useEffect(() => {
    setScreenHeight(window.innerHeight);
  }, []);

  return (
    <>
      <ImagePreload srcSet={HERO_IMAGE} />
      <Box
        marginBottom={{ xs: '0', lg: '24x' }}
        maxWidth="100vw"
        tag="section"
        width="100%"
      >
        <StyledBox
          display="flex"
          flexDirection="column"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Image
            srcSet={HERO_IMAGE}
            alt="Imagen de dos tarjetas moradas flotando con un fondo violeta"
            height={{ xs: `${screenHeight}px`, lg: 'auto' }}
            width="100%"
            objectFit="cover"
            objectPosition="top"
          />

          <Box
            position="absolute"
            display="flex"
            flexDirection={{ xs: 'column' }}
            alignItems={{ xs: 'center', lg: 'flex-start' }}
            justifyContent={{ xs: 'space-between', lg: 'center' }}
            height={{ xs: `${screenHeight - (screenHeight / 25)}px`, lg: '100vh' }}
            paddingHorizontal={{ xs: '4x', md: '12x', lg: '16x' }}
            width="95vw"
          >
            <StyledTypography
              variant="heading1"
              tag="h1"
              marginBottom={{ xs: '6x', md: '18x', lg: '8x' }}
              marginTop={{ xs: '18x', lg: '0' }}
              color="white"
              textAlign={{ xs: 'center', lg: 'left' }}
              whiteSpace="pre-line"
            >
              {'100 millones en el \n mundo ya son parte \n de la era Nu, faltas tú'}
            </StyledTypography>

            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: 'center', lg: 'flex-start' }}
              justifyContent={{ xs: 'center', lg: 'flex-start' }}
            >

              <TypographyColorHighlight
                intlKey="HOME.MASTERBRAND.SUBTITLE.VARIANT_2"
                variant="subtitle1"
                whiteSpace={{ xs: 'inherit', lg: 'pre-line' }}
                tag="p"
                textAlign={{ xs: 'center', lg: 'left' }}
                color="white"
                strong
                maxWidth={{ xs: '342px', md: '380px', lg: '470px' }}
              />

              <FormBoxExperiment
                buttonLabel="HOME.MULTIPRODUCT.HERO.CTA.BTN"
                inputAndButtonWidth={{ xs: '335px' }}
                buttonBackgroundColor="#820AD1"
                buttonGradientColor="linear-gradient(99.92deg, #9F2DEB 18.57%, #6200A3 71.77%),linear-gradient(0deg, #2DB965, #2DB965)"
                buttonLabelColor="#FFFFFF"
                inputTransparentBackground
                inputLabelColor="#FFFFFF"
                ctaHide
              />
            </Box>
          </Box>

        </StyledBox>
      </Box>
    </>
  );
};

export default HeroSection;
