/* eslint-disable react/prop-types */
import React from 'react';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import CTAVariant1 from './variants/CTAVariant1';
import CTAVariant2 from './variants/CTAVariant2';
import CTAVariant3 from './variants/CTAVariant3';
import CTAControl from './variants/CTAControl';

const CTA_XP_ID = '347693';
const CTA_XP_VARIANT_1_ID = '47014';
const CTA_XP_VARIANT_2_ID = '47015';
const CTA_XP_VARIANT_3_ID = '47016';

function HeroCTA({
  buttonBackgroundColor,
  buttonGradientColor,
  buttonLabel,
  buttonLabelColor,
  buttonStyleVariant,
  inputBoxShadow,
  inputLabel,
  inputLabelColor,
  inputTransparentBackground,
  errorMessageColor,
}) {
  const activeVariant = useFigPiiExperiment(CTA_XP_ID);

  if (activeVariant === CTA_XP_VARIANT_1_ID) {
    return (
      <CTAVariant1
        buttonLabel={buttonLabel}
        buttonStyleVariant={buttonStyleVariant}
        buttonLabelColor={buttonLabelColor}
      />
    );
  }

  if (activeVariant === CTA_XP_VARIANT_2_ID) {
    return (
      <CTAVariant2
        buttonLabel={buttonLabel}
        buttonBackgroundColor={buttonBackgroundColor}
      />
    );
  }

  if (activeVariant === CTA_XP_VARIANT_3_ID) {
    return (
      <CTAVariant3
        buttonLabel={buttonLabel}
        buttonBackgroundColor={buttonBackgroundColor}
      />
    );
  }

  return (
    <CTAControl
      buttonGradientColor={buttonGradientColor}
      buttonLabel={buttonLabel}
      buttonLabelColor={buttonLabelColor}
      buttonStyleVariant={buttonStyleVariant}
      inputBoxShadow={inputBoxShadow}
      inputLabel={inputLabel}
      inputLabelColor={inputLabelColor}
      inputTransparentBackground={inputTransparentBackground}
      errorMessageColor={errorMessageColor}
    />
  );
}

export default HeroCTA;
